import { customElement, autoinject } from "aurelia-framework";
import { Router } from "aurelia-router";
import { ReleaseNoteService } from "../../../../services/releaseNoteService";
import { releaseNoteRoutes, validateFolderPath, validateFileName } from '../../releasenotedatamodel';
import { createCustomEvent } from '../../../../utility/customEventHelper';
import '../../releasenotespage.css';

@customElement("addFile")
@autoinject()
export class AddFileComponent {

    fileUploadControl: HTMLInputElement;
    fileUploadControlForm: HTMLFormElement;
    folderPath: string;
    publishedAfterDate: Date = new Date();

    fileControlError: string = '';

    constructor(private releaseNoteService: ReleaseNoteService, private router: Router) {
    }

    bind(): void {
        this.folderPath = '';
        this.publishedAfterDate = new Date();
        this.fileUploadControlForm.reset();
    }

    attached(): void {
        this.doFileControlValidation();
    }

    async add(): Promise<void> {
        if (!this.isFileSelected()) {
             return;  // validation error still being shown
        }

        const file = this.selectedFile();
        await this.releaseNoteService.uploadReleaseNoteFile(file, this.folderPath, this.publishedAfterDate);
        this.router.navigateToRoute(releaseNoteRoutes.list, { nofilter: 1 });
    }

    cancel(): void  {
        this.router.navigateToRoute(releaseNoteRoutes.list);
    }

    validateFolderPath = (): boolean | string => {
        return (validateFolderPath(this.folderPath) ? true : 'UI_ReleaseNotes_FolderPathHelpText');
    }

    private isFileSelected(): boolean {
        if (!!this.fileUploadControl.files) {
            return this.fileUploadControl.files.length > 0;
        } 
        return false;
    }

    private okFilename(): boolean {
        const f = this.fileUploadControl.files !== null && this.fileUploadControl.files[0];
        if (!f) {
            return false;
        } 
        return validateFileName(f.name);
    }


    private selectedFile(): File {
        if (!!this.fileUploadControl.files) {
            return this.fileUploadControl.files[0];
        } 
        throw new Error("No file selected");
    }
    
    private doFileControlValidation(): void {
        if (!this.isFileSelected() || !this.okFilename()) {
            this.fileUploadControl.dispatchEvent(
                createCustomEvent('validationerrors', this.fileUploadControl)
            );
            this.fileControlError = 'UI_Common_ValidationError_Required_File';
        } 
        else {
            this.fileUploadControl.dispatchEvent(
                createCustomEvent('validationsuccess', this.fileUploadControl)
            );
            this.fileControlError = '';
        }
    }
}