import { getLogger } from 'aurelia-logging';
import { autoinject, PLATFORM } from 'aurelia-framework';
import { BaseViewModel } from '../common/BaseViewModel';
import { RouterConfiguration } from 'aurelia-router';
import { releaseNoteRoutes } from './releasenotedatamodel';
import './releasenotespage.css';

@autoinject()
export class ReleaseNotesPage extends BaseViewModel<void> {

    constructor() {
        super(getLogger(ReleaseNotesPage.name));
    }

    configureRouter(config: RouterConfiguration): void {
        config.title = 'List';
        config.map([
            { route: '', redirect: 'list' },
            { route: 'list/:nofilter?', name: releaseNoteRoutes.list, href: "list", moduleId: PLATFORM.moduleName('pages/releasenotes/modules/releasenoteslist/releasenoteslist'), nav: true, title:'Release notes' },
            { route: 'add', name: releaseNoteRoutes.add, moduleId: PLATFORM.moduleName('pages/releasenotes/modules/addfile/addfile'), nav: true, title:'Add new release note' },
            { route: 'edit/:releaseNoteFileId', name: releaseNoteRoutes.edit, href: "edit", moduleId: PLATFORM.moduleName('pages/releasenotes/modules/edit/edit'), nav: true, title:'Edit release note' }
        ]);
    }
}
  
  