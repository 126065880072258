import { endpoints } from '../config';
import { autoinject, TaskQueue } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { getLogger } from 'aurelia-logging';
import { BaseService } from './baseService';
import { baseConfig } from '../config/endpoints';

@autoinject()
export class ReleaseNoteService extends BaseService {
    constructor(httpClient: HttpClient, taskQueue: TaskQueue) {
        super(httpClient, getLogger(ReleaseNoteService.name), taskQueue);
    }

    async uploadReleaseNoteFile(file: File, folderPath: string, publishedAfter: Date): Promise<string> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('folderPath', folderPath);
        formData.append('publishedAfter', publishedAfter.toISOString());
    
        return await this.httpRequest<string>(endpoints.uploadReleaseNote(formData));
    }

    async deleteReleaseNoteFile(releaseNoteFileId: number, deleteBlob: boolean): Promise<string> {
        const request = (new Request(`${baseConfig.api}/graphql/releasenote?releaseNoteId=${releaseNoteFileId}&deleteBlob=${deleteBlob}`, {
            method: 'DELETE'
            }
        ));
        return await this.httpRequest<string>(request);
    }
}