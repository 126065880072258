import { bindable, customElement } from "aurelia-framework";
import { isSomething } from "../../../../utility";
import { UserFeatures } from "../../../../config/sessionService";
import { ViewFile } from "../../releasenotedatamodel";
import './fileitem.css';

@customElement('fileitem')
export class FileItem {
    @bindable()
    file: ViewFile;

    @bindable()
    askToDeleteFile: (params: { file: ViewFile }) => void;

    @bindable()
    features: UserFeatures; 

    constructor() { }

    /**
     * bound function should take a "releaseNoteFileId: number" parameter
     */
    @bindable()
    editReleaseNote: (params: any) => void;

    edit(): void {
        if (isSomething(this.editReleaseNote) && isSomething(this.file)) {
            this.editReleaseNote({ releaseNoteFileId: this.file.releaseNoteFileId })
        }
    }

    delete(): void {
        if (isSomething(this.askToDeleteFile)) this.askToDeleteFile({ file: this.file })
    }
}
