import { bindable, customElement } from "aurelia-framework";
import { UserFeatures } from "../../../../config/sessionService";
import { isSomething } from "../../../../utility";
import { ViewFolder, ViewFile } from "../../releasenotedatamodel";
import './folderlist.css';

@customElement("folderlist")
export class FolderList {

    @bindable()
    public folder: ViewFolder | undefined;

    @bindable() 
    public expanded: boolean;

    @bindable({ changeHandler: 'parentExpandedChanged' }) 
    public parentExpanded: boolean;

    @bindable()
    public askToDeleteFile: (params: { file: ViewFile }) => void;

    @bindable()
    public features: UserFeatures;

    /**
     * bound function should take a "releaseNoteFileId: number" parameter
     */
     @bindable()
     editReleaseNote: (params: any) => void;

    folderClick(event: MouseEvent): void {
        if (isSomething(this.folder) && this.folder.isRootFolder === false) {
            this.expanded = !this.expanded;     // don't collapse the root folder
        }
        event.stopPropagation();
    }

    /** Closes all sub-folders when its parent folder closes.
    */
    parentExpandedChanged(newValue: boolean | undefined | null): void {
        if (isSomething(newValue) && newValue === false) {
            this.expanded = false;
        }
    }
}
