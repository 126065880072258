import { PublishedReleaseNotesQuery, 
    PublishedReleaseNotesQuery_releaseNotes_all } from "../../../custom_typings/graphql";
import { isNone, isSomething } from "../../utility/helpers";

export class ViewFile {
    constructor(
        public fileName: string,
        public readUrl: string,
        public publishedAfterDate: string,
        public releaseNoteFileId: number) {
    }
}

export class ViewFolder {
    constructor(
        public isRootFolder: boolean,
        public folderName: string,
        public subFolders: ViewFolder[],
        public files: ViewFile[]) {
    }
}

export type FileData = {
    name: string
}

type fileAndPath = {
    file: PublishedReleaseNotesQuery_releaseNotes_all
    folderNames: string[]
}


const pathSeparator: string = "/";

export function convertToViewFolders(data: PublishedReleaseNotesQuery): ViewFolder {
    let rootFolder = new ViewFolder(true, "", [], []);
    
    if (isNone(data) || isNone(data.releaseNotes) || data.releaseNotes === null || isNone(data.releaseNotes.all)) {
        return rootFolder;
    }

    let allFileInfos: fileAndPath[] = (data.releaseNotes.all
                                        .map(releaseNoteFile => ({ 
                                            file: releaseNoteFile,
                                            folderNames: valueOrEmptyString(releaseNoteFile.releaseNoteFolder.folderPath)
                                                            .split(pathSeparator)
                                        })));
    
    for (let fileInfo of allFileInfos) {
        createFolderStructure(rootFolder, fileInfo.folderNames);
        putFileInFolder(rootFolder, fileInfo.folderNames, fileInfo.file);
    }

    return rootFolder;
}


export enum releaseNoteRoutes {
    list = 'releasenoteslist',
    add = 'releasenoteadd',
    edit = 'releasenoteedit'
}

const folderPathRegEx: RegExp = /^\w+( \w+)*$/;

export function validateFolderPath(folderPath: string): boolean | string {
    if (isNone(folderPath)) return false;
    
    const terms = folderPath.split('/');
    return terms.every(term => folderPathRegEx.test(term));
}


export function validateFileName(filename: string): boolean {
    if (isNone(filename)) return false;

    if (filename.length < 5 || !filename.toLowerCase().endsWith(".pdf")) return false;
    return true;
}

/**
 * Turns a string path like "a/b/c" into ViewFolder objects. In this case ViewFolder "a" contains ViewFolder "b", which 
 * in turn contains "c". Note that "a" will be nested under the initial "root" ViewFolder.
 * 
 * The ViewFolder objects are added to an initial "root" ViewFolder, which is passed in as the initial "currentFolder" parameter.
 * 
 * This function works recursively to create a ViewFolder object from each string (folder name) in the "restFolderPath" parameter.
 *  
 * @returns void, but adds the new ViewFolder objects as an object graph under/in the inital "currentFolder" parameter.
 */
function createFolderStructure(currentFolder: ViewFolder, restFolderPath: string[]): void {
    if (!currentFolder || !currentFolder.subFolders || !currentFolder.files || !restFolderPath) {
        throw new Error(`Invalid parameters to ${createFolderStructure.name} function`);
    }
    
    if (restFolderPath.length == 0) {
        return;
    }

    let newFolderName = restFolderPath[0];
    let existingFolder = currentFolder.subFolders.find(folder => folder.folderName === newFolderName);
    
    if (isSomething(existingFolder)) {
        // folder has already been created, so just continue using it:
        createFolderStructure(existingFolder, restFolderPath.slice(1));
        return;
    }

    // create a new sub folder, and then its own subtree of folders:
    let newFolder = new ViewFolder(false, newFolderName, [], []);
    currentFolder.subFolders.push(newFolder);
    
    createFolderStructure(newFolder, restFolderPath.slice(1));
}


/**
 * Places a "file" in the correct ViewFolder object. The function looks through the object graph starting with "currentFolder",
 * and places the file when it finds the correct ViewFolder. 
 * 
 * The path searche for is given by the "restFolderPath" parameter. Eg if it is "a/b/c" then the file will be placed in the "c" ViewFolder 
 * object, which is nested in "b", which again is nested in "a". Note that ViewFolder "a" is itself nested under the initial "root" folder.
 */
function putFileInFolder(currentFolder: ViewFolder, restFolderPath: string[], file: PublishedReleaseNotesQuery_releaseNotes_all): void {
    if (restFolderPath.length == 0) {
        currentFolder.files.push(new ViewFile(file.blob.fileName, file.blob.readUrl, file.publishedAfter, file.releaseNoteFileId));
    
    } else {
        const folderName = restFolderPath[0];
        let folderToVisit = currentFolder.subFolders.find(folder => folder.folderName === folderName);
        
        if (folderToVisit === undefined) {
            const fileName = isSomething(file) && isSomething(file.blob) ? file.blob.fileName : "undefined";
            throw new Error(`Folder not found "${folderName}" for file "${fileName}"`);
        }
        putFileInFolder(folderToVisit, restFolderPath.slice(1), file);
    }
}


function valueOrEmptyString(str: string | undefined | null) {
    return isSomething(str) ? str : "";
}