import { GraphQLBaseViewModel } from '../../../common/GraphQLBaseViewModel';
import { getLogger } from 'aurelia-logging';
import gql from'graphql-tag';
import { PublishedReleaseNotesQuery, PublishedReleaseNotesQueryVariables } from '../../../../../custom_typings/graphql';
import { convertToViewFolders, releaseNoteRoutes, ViewFile, ViewFolder } from '../../releasenotedatamodel';
import { isSomething } from '../../../../utility';
import { autoinject, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ReleaseNoteService } from '../../../../services/releaseNoteService';
import './releasenoteslist.css';

@autoinject()
export class ReleaseNotesList extends GraphQLBaseViewModel<void, PublishedReleaseNotesQuery, PublishedReleaseNotesQueryVariables> {

    constructor(private router: Router,
                private releaseNoteService: ReleaseNoteService) {
        super(getLogger(ReleaseNotesList.name));
    }

    editReleaseNote(releaseNoteFileId: number | null | undefined): void {
        if (isSomething(releaseNoteFileId)) {
            this.router.navigateToRoute("releasenoteedit", { releaseNoteFileId: releaseNoteFileId })
        }
    }
    
    query = gql`
        query PublishedReleaseNotesQuery($allFiles:Boolean) {
            releaseNotes {
                all(allFiles:$allFiles) {
                    releaseNoteFileId
                    releaseNoteFolder {
                        releaseNoteFolderId
                        folderPath
                    }
                    blob {
                        blobId
                        fileName
                        size
                        readUrl
                        mimeType
                    }
                    publishedAfter
                    lastModified
                }
            }
        }`;

    private noFilterOnReleaseNotes: boolean = false;

    activate(params: any): void {
        // "nofilter" parameter may be any value except undefined or null. If present then only try nofilter if the user has the required filter.
        // A security check is done on the back end, so it is not possible to maliciously bypass this
        this.noFilterOnReleaseNotes = isSomething(params.nofilter) ? this.features.releaseNoteEditor.write : false;
        this.variables = { allFiles: this.noFilterOnReleaseNotes }
    }

    attached() {
        this.variables = { allFiles: this.noFilterOnReleaseNotes }
    }

    toggleNoFilter(noFilter: boolean): void {
        const routeParams = noFilter ? { nofilter: 1 } : { };
        this.router.navigateToRoute(releaseNoteRoutes.list, routeParams);
    }

    @computedFrom('data')
    get folderStructure(): ViewFolder | undefined {
        if (this.data === undefined) {
            return undefined;
        }
        return convertToViewFolders(this.data);
    }

    @computedFrom('folderStructure')
    get hasNotes(): boolean {
        return (isSomething(this.folderStructure) 
                ? this.folderStructure.subFolders.length > 0
                : false);
    }

    refetch = (): void => this.revalidateQuery && this.revalidateQuery();
    
    /**
     * fileToDelete is set by event handler(s) in the view. Its value ultimately comes from one of the child views. 
     * When fileToDelete is set to a ViewFile then the modal delete dialog is shown.
     */
    fileToDelete: ViewFile | undefined = undefined;

    /**
     * Called if the user presses "YES" in the delete modal dialog.
     */
    async performDeletion(): Promise<string> {
        if (this.fileToDelete === undefined) {
            throw new Error('file to delete was undefined');
        }
        const result = await this.releaseNoteService.deleteReleaseNoteFile(this.fileToDelete.releaseNoteFileId, true);
        this.refetch();
        this.hideDeleteModal();
        return result;
    }

    hideDeleteModal(): void {
        this.fileToDelete = undefined;
    }

    navigateToAdd(): void {
        this.router.navigateToRoute(releaseNoteRoutes.add);
    }
}
  
  