import gql from'graphql-tag';
import { isNone, isSomething } from '../../../../utility';
import { customElement, autoinject } from "aurelia-framework";
import { GraphQLBaseViewModel } from '../../../common';
import { EditableReleaseNoteQuery, EditableReleaseNoteQueryVariables, UpdateReleaseNoteFile, UpdateReleaseNoteFileInput, UpdateReleaseNoteFileVariables } from '../../../../../custom_typings/graphql';
import { getLogger } from 'aurelia-logging';
import { Router } from 'aurelia-router';
import { releaseNoteRoutes, validateFolderPath, validateFileName } from '../../releasenotedatamodel';
import '../../releasenotespage.css';

@customElement("editReleaseNote")
@autoinject()
export class Edit extends GraphQLBaseViewModel<void, EditableReleaseNoteQuery, EditableReleaseNoteQueryVariables> {

    releaseNoteFileId: number | undefined;

    constructor(private router: Router) {
        super(getLogger(Edit.name));
    }

    activate(params: any): void {
        if (isNone(params.releaseNoteFileId) || !this.features.releaseNoteEditor.write) {
            this.logger.info("No releaseNoteFileid parameter, or no permission. Redirecting to list.");
            this.router.navigateToRoute("releasenoteslist");
        }

        this.releaseNoteFileId = parseInt(params.releaseNoteFileId, 10);
        this.variables = {
            releaseNoteFileId: this.releaseNoteFileId
        }
    }

    fileReadUrl: string;
    editableFolderPath: string;
    originalFilename: string;
    editableFilename: string;
    editablePublishAfterDate: Date | undefined;

    errorContent: string;

    query = gql`
        query EditableReleaseNoteQuery($releaseNoteFileId:Int!) {
            releaseNotes {
                one(releaseNoteFileId:$releaseNoteFileId) {
                    releaseNoteFileId
                    releaseNoteFolder {
                        releaseNoteFolderId
                        folderPath
                    }
                    blob {
                        blobId
                        fileName
                        readUrl
                    }
                    publishedAfter
                    lastModified
                }
            }
        }`;

    dataChanged = (data: EditableReleaseNoteQuery) => {
        if (isSomething(data) && isSomething(data.releaseNotes) && isSomething(data.releaseNotes.one)) {
            this.editableFolderPath = data.releaseNotes.one.releaseNoteFolder.folderPath;
            this.editablePublishAfterDate = new Date(data.releaseNotes.one.publishedAfter);
            this.fileReadUrl = data.releaseNotes.one.blob.readUrl;
            this.originalFilename = data.releaseNotes.one.blob.fileName;
            this.editableFilename = this.originalFilename;
        }
    }

    refetch = (): void => this.revalidateQuery && this.revalidateQuery();

    detached() {
        super.detached();
        this.releaseNoteFileId = undefined;
        this.errorContent = '';
        this.originalFilename = '';
        this.editableFilename = '';
        this.editablePublishAfterDate = new Date();
        this.editableFolderPath = '';
    }

    async submit() {
        if (this.releaseNoteFileId === undefined) return;

        const input: UpdateReleaseNoteFileInput = ({
            releaseNoteFileId: this.releaseNoteFileId,
            folderPath: this.editableFolderPath,
            fileName: this.editableFilename,
            publishedAfterDate: this.guaranteeDate(this.editablePublishAfterDate).toISOString()
        });

        const mutation = gql`
            mutation UpdateReleaseNoteFile($input: UpdateReleaseNoteFileInput!) {
                updateReleaseNote(input: $input) {
                    code
                    success
                    message
                }
            }`;

        const result = await this.runQuery<UpdateReleaseNoteFile, UpdateReleaseNoteFileVariables>(
                                    mutation, 
                                    { 
                                        input
                                    });
        
        if(!result.updateReleaseNote.success) {
            this.errorContent = result.updateReleaseNote.message;
            throw new Error(result.updateReleaseNote.message)
        }
        await this.revalidateAllActiveQueries();
        this.navigateToList();
    }

    cancel(): void {
        this.navigateToList();
    }

    validateFolderPath = (): boolean | string => {
        return (validateFolderPath(this.editableFolderPath) ? true : 'UI_ReleaseNotes_FolderPathHelpText');
    }

    validateFileName = (): boolean | string => {
        return (validateFileName(this.editableFilename) ? true : 'UI_ReleaseNotes_InvalidFilename');
    }

    private guaranteeDate(dt: Date | undefined): Date {
        return !!dt ? dt : new Date();
    }

    private navigateToList(noFilter: boolean = true) {
        if (noFilter) {
            this.router.navigateToRoute(releaseNoteRoutes.list, { nofilter: 1 });
        } else {
            this.router.navigateToRoute(releaseNoteRoutes.list);
        }
    }
}